<template>
  <svg :alt="alt" :width="width" :height="height">
    <use :xlink:href="url" width="100%" height="100%" />
  </svg>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import type { MediaIconFragment } from '#graphql-operations'
import { slugify } from '~/helpers/slugify'

const props = defineProps({
  // eslint-disable-next-line vue/prop-name-casing
  __typename: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  changed: {
    type: Object as PropType<MediaIconFragment['changed']>,
    default: null,
  },
  svg: {
    type: Object as PropType<MediaIconFragment['svg']>,
    default: null,
  },
  forcedWidth: {
    type: Number,
    default: null,
  },
  forcedHeight: {
    type: Number,
    default: null,
  },
})

const alt = computed(() => {
  return props.svg?.first?.alt || ''
})

const width = computed(() => {
  return props.forcedWidth || props.svg?.first?.width?.toString()
})

const height = computed(() => {
  return props.forcedHeight || props.svg?.first?.height?.toString()
})

const url = computed(() => {
  const changed = props.changed?.first?.formatted || ''
  return `/api/icon/${props.id}--${slugify(alt.value)}.svg?h=${changed}#icon`
})
</script>

<script lang="ts">
export default {
  name: 'MediaIcon',
}
</script>
